:root {
  --color-dark-gray: #323232;
}

body {
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
  color: var(--color-dark-gray);
  /* background-color: #f4f4f4;
  margin-top: 72px;
  margin-left: 236px;
  padding: 20px;
  overflow-y: hidden; */
  transition: all 0.5s;
}

p {
  font-size: 14px;
}

.flexContainer {
  display: flex;
  justify-content: space-between;
}

.flexContainerLeft {
  display: flex;
  justify-content: left;
}

@media (max-height: 768px) {
  /* body {
    height: 100vh;
    overflow-y: auto; 
  } */
}
